.wrapper {
  overflow: hidden;
  padding: 0 10px 174px 10px;
}
.container {
  display: flex;
  & > div:first-child {
    flex: 2;
  }
  & > div:last-child {
    margin-top: 5px;
    flex: 0.9;
  }
  padding-bottom: 250px;
}
.intro {
  & > h1 {
    font-size: 3.2rem;
    font-weight: 700;
    margin-top: 8px;
    min-height: 33px;
  }
  & > span {
    color: rgba(0, 0, 0, 0.8);
    font-size: 1.6rem;
    line-height: 1.6;
    & > a {
      color: var(--primary-color);
      font-weight: 600;
      font-size: 1.6rem;
      line-height: 1.6;
      text-decoration: none;
    }
  }
  & > div {
    & > h2 {
      font-size: 2rem;
      font-weight: 700;
      margin: 36px 0 4px;
    }
  }
}
.benefits {
  display: flex;
  margin-top: 10px;
  flex: 1;
}

.benefits-list {
  display: flex;
  flex: 1;
  gap: 8px;
  flex-direction: column;
  align-items: flex-start;
  & > div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    color: var(--primary-color);
    & > span {
      font-size: 1.6rem;
      line-height: 1.6;
      color: #333;
    }
  }
}
.uudai {
  & > li {
    margin: 7px 0;
  }
}
.icon {
  font-size: 1.4rem;
  color: var(--primary-color);
}
.member {
  font-family: var(--system-ui);
  & > h2 {
    font-size: 2rem;
    font-weight: 700;
    margin: 20px 0 4px;
  }
  & > div {
    margin-top: 10px;
    display: flex;
    color: var(--text-color);
    & > div {
      width: 25%;
      display: flex;
      align-items: center;
      gap: 5px;
      & > span {
        font-size: 1.4rem;
        line-height: 1.6;
        color: var(--text-color);
      }
    }
  }
}
.xstk {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 5px;
  & > span {
    font-size: 1.4rem;
    line-height: 1.6;
    color: var(--text-color);
  }
}
.m_member {
  & > h2 {
    margin: 10px 0 0 0 !important;
  }
  & > span {
    color: rgba(0, 0, 0, 0.8);
    font-size: 1.4rem;
    line-height: 1.6;
  }
}
.table {
  margin-top: 20px;
  width: 100%;
}
.point {
  font-weight: 600;
  font-size: medium;
}
.document {
  & > div {
    & > h2 {
      font-size: 2rem;
      font-weight: 700;
      margin: 36px 0 4px;
    }
  }
}
.basic-course {
  margin-top: 12px;
  background: #f5f5f5;
  border: 1px solid #ebebeb;
  border-radius: 6px;
  padding: 0;
  top: 66px;
  padding: 12px 20px 12px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: -webkit-sticky;
  position: sticky;
  z-index: 1;
  & > span {
    font-size: 1.3rem;
    text-align: center;
  }
  & > div {
    display: flex;
    align-items: center;
    & > span {
      color: var(--text-color);
      font-size: 1.6rem;
      font-weight: 600;
      overflow: hidden;
      position: relative;
      display: block;
      margin-left: 10px;
    }
  }
  &:hover {
    cursor: pointer;
  }
}
.program {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
  & > span {
    color: var(--primary-color);
    font-size: 1.4rem;
    line-height: 1.6;
  }
  & > a {
    color: var(--primary-color);
    font-size: 1.4rem;
    line-height: 1.6;
    text-decoration: none;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }
}
.advertisement-desktop {
  display: block;
}
.advertisement-mobile {
  display: none;
}
.container-advertisement {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 20px 20px;
  & > h5 {
    color: var(--primary-color);
    font-size: 32px;
    font-weight: 400;
    margin: 0 auto;
    opacity: 0.8;
    margin-bottom: 10px;
  }
}
.image {
  object-fit: contain;
  width: 100%;
  border-radius: 16px;
}
.detail-advertisement {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 10px 50px 10px 50px;

  & > div {
    display: flex;
    justify-content: flex-start;
    gap: 15px;
    align-items: center;
    & > span {
      color: #494949;
      font-size: 1.4rem;
      line-height: 1.6;
    }
  }
}
.assignment-container {
  & > h2 {
    font-size: 2rem;
    font-weight: 700;
    margin: 20px 0 4px;
  }
}
.assignment {
  margin: 20px 0;
  & > span {
    margin-bottom: 10px;
    display: block;
    color: var(--primary-color);
    font-style: italic;
    font-size: 1.4rem;
    line-height: 1.6;
  }
  & > div {
    width: 100%;
    & > img {
      display: block;
      transition: all 0.25s ease;

      object-fit: contain;
      width: 80%;
      border: 1px solid #ebebeb;

      border-radius: 16px;
      &:hover {
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      }
    }
  }
}
.active {
  position: fixed;
  z-index: 999;
  & > img {
    width: 70% !important;
  }
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  animation: fadeIn ease 0.5s;
}
.warn {
  color: var(--primary-color);
}
.voucher {
  padding: 0 0 0 0;
  margin: 0;
}
.gift {
  padding: 0;
  margin: 5px 0;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.combo {
  display: flex;
  gap: 20px;
  & > div {
    display: flex;
    align-items: center;
    width: 40% !important;
    & > img {
      height: 2rem;
      margin-bottom: 5px;
    }
    & > span {
      font-weight: 600;
      font-size: 2rem;
      & > span {
        color: var(--primary-color);
      }
    }
  }
}
.hot {
  display: flex;
  align-items: center;

  & > h2 {
    margin: 0;
    font-size: 2rem;
  }
  & > img {
    height: 30px;
    width: 25px;
    margin: 0 0 7px 0;
  }
}
.play {
  font-size: 5.5rem;

  color: #fff;
}
.container-play {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 1px;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00000026;
  cursor: pointer;
  border-radius: 16px;
}
.container-image {
  position: relative;
}
//responsive

@media (max-width: 1024px) {
  .detail-advertisement {
    padding: 10px 20px 10px 20px;
  }
  .wrapper {
    overflow: hidden;
    padding: 0 0px 74px;
  }
  .member {
    & > div {
      flex-wrap: wrap;
      & > div {
        width: 50%;
      }
    }
  }
  .member1 {
    & > div {
      & > div {
        width: 100%;
      }
    }
    & > h2 {
      font-size: 2rem;
      font-weight: 700;
      margin: 20px 0 4px;
    }
  }
  .active {
    & > img {
      width: 80% !important;
    }
  }
  .member-detail {
    width: 50%;
  }
  .container-advertisement {
    padding: 10px;
  }
  .assignment {
    margin: 20px 0;
    & > div {
      & > img {
        width: 90%;
      }
    }
  }
}
@media (max-width: 768px) {
  .detail-advertisement {
    padding: 10px 00px 10px 00px;
  }
  .active {
    & > img {
      width: 90% !important;
    }
  }
  .container {
    display: flex;
    flex-direction: column;
  }

  .image {
    object-fit: contain;
    margin: 2px auto;
    width: 40%;
    border-radius: 16px;
  }

  .advertisement-desktop {
    display: none;
  }
  .advertisement-mobile {
    display: block;
  }
  .container-advertisement {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    padding: 10px 10px;
  }
  .container-image {
    width: 50%;
    flex-direction: column;
    display: flex;
    justify-content: center;
    gap: 10px;
    align-items: center;
  }
  .image {
    object-fit: contain;
    margin: 2px auto;
    width: 70%;
    border-radius: 16px;
  }
  .assignment {
    margin: 20px 0;
    & > div {
      & > img {
        width: 100%;
      }
    }
  }
}

@media (max-width: 576px) {
  .benefits {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    flex: 1;
    gap: 8px;
  }
  .benefits-list {
    gap: 8px;
  }
  .active {
    & > img {
      width: 95% !important;
    }
  }
  .image {
    width: 100%;
    border-radius: 8px;
    margin-left: -5px;
    & ~ div {
      margin-left: -5px;
    }
  }
  .container-play {
    border-radius: 8px;
  }
  .detail-advertisement {
    margin-top: 10px;
    gap: 0px;
  }
  .program {
    flex-direction: column;
    align-items: flex-start;
  }
  .document {
    margin-top: 20px;
  }
  .document {
    & > div {
      & > h2 {
        margin: 15px 0 4px;
      }
    }
  }
  .basic-course {
    padding: 10px 12px;
  }
  .program {
    margin: 10px 0 10px 0;
  }
  .combo {
    & > div {
      width: 45% !important;
    }
  }
}
